<template>
    <div>
        <div>
            <Breadcrumb content="文章管理"/>
        </div>
        <div>
            <el-button-group>
                <el-button @click="refresh" type="primary" icon="el-icon-refresh-left" title="刷新"></el-button>
                <el-button type="warning" @click="$router.push('articleAdd')">新增文章</el-button>
                <el-button type="success" @click="$router.push({path:'articleEdit',query:{type:100}})">导航-新手引导</el-button>
                <el-button type="danger"  @click="$router.push({path:'articleEdit',query:{type:99}})">导航-规则</el-button>
            </el-button-group>
        </div>
        <div>
            <el-table
                    :data="formatList"
                    border
                    size="small"
                    stripe
                    style="width: 100%">
                <el-table-column
                        prop="created_at"
                        label="发布时间"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="typeName"
                        label="文章类型"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="title"
                        label="文章标题"
                        >
                </el-table-column>
                <el-table-column
                        prop="schema"
                        label="简介"
                        >
                </el-table-column>
                <el-table-column width="180" label="操作">
                    <template slot-scope="scope">
                        <el-button
                                size="mini"
                                @click="handleEdit(scope.row)"
                        >
                            编辑
                        </el-button>
                        <el-button
                                size="mini"
                                type="warning"
                                @click="handleDel(scope.row)"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style="padding:20px 0">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="pageSizes"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/admin/breadcrumb'
    export default {
        name: "index",
        components:{Breadcrumb},
        data:function () {
            return {
                pageSize:this.$webConfig.pages.pageSize,
                pageSizes:this.$webConfig.pages.pageSizes,
                currentPage:1,
                tableData: [],
                total:0
            }
        },
        methods:{
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
                this.currentPage = val;
                this.getList();
            },
            handleDel(row){
                let id = row.id;
                this.$confirm("是否确认删除该条数据?").then(()=>{
                    this.$axios.post("site/article/del",{id})
                        .then((res)=>{
                            if (res.data.code == 1000){
                                this.$message.success("删除成功");
                                this.getList()
                            }else{
                                this.$message.error(res.data.msg)
                            }
                        })

                });
            },
            handleEdit(row){
                let id = row.id;
                this.$router.push({path:"articleEdit",query:{id}})
            },
            getList:function () {
                let page = this.currentPage;
                let limit = this.pageSize;
                this.$axios.post("site/article/getList",{page,limit})
                    .then((res) => {
                        if (res.data.code == 1000){
                            this.tableData = res.data.data.list;
                            this.total = res.data.data.total;
                        }
                    })
            },
            refresh:function () {
                this.currentPage = 1;
                this.getList();
                this.$message.success("列表刷新成功")
            }
        },
        created() {
            this.getList();
        },
        computed :{
            formatList(){
                let arr = [];
                this.tableData.forEach((v)=>{
                    console.log(v)
                    v.typeName = this.$webConfig.articleTypes[v.type].name;
                    arr.push(v);
                });
                return arr;
            }
        }
    }
</script>

<style scoped>

</style>
